@import "@dex/bubl-web/src/styles/Global.scss";
// Colors

$primary: #5afc04;
$secondary: #77DD77;
$alt: #5afc04;
$error: #ff3333;

$lightBg: #eef1f5;

$light0: white;
$light1: #f4f5f6;
$light2: #eaedf2;
$light3: #d8dee8;
$light4: #bcc5d3;
$light5: #a3a9af;

$dark0: black;
$dark1: #232227;
$dark2: #33343e;
$dark3: #4b475a;
$dark4: #5e5f6b;
$dark5: #a3a9af;

// Fonts Size

$xs: 10px;
$s: 12px;
$m: 14px;
$l: 16px;
$xl: 18px;
$xxl: 20px;
$xxxl: 30px;

// Font Family

$fonts: ("": "",
    "primary": unquote("Roboto, sans-serif"),
);

// Text Styles

@mixin bodyText($args: ()) {
    $defauls: (
        "weight": 400,
        "family": "primary",
        "color": $dark0,
        "size": $m,
    );

    @include text($args, $defauls);
}

@mixin paragraphText($args: ()) {
    $defauls: (
        "weight": 400,
        "family": "primary",
        "color": $dark0,
        "size": $m,
        "line-height": 1.4,
    );

    @include text($args, $defauls);
}

@mixin tinyText($args: ()) {
    $defauls: (
        "weight": 500,
        "family": "primary",
        "color": $dark0,
        "size": $xs,
    );

    @include text($args, $defauls);
}

@mixin metaText($args: ()) {
    $defauls: (
        "weight": 400,
        "family": "primary",
        "color": $dark0,
        "size": $s,
    );

    @include text($args, $defauls);
}

@mixin subHeadingText($args: ()) {
    $defauls: (
        "weight": 600,
        "family": "primary",
        "color": $dark0,
        "size": $m,
    );

    @include text($args, $defauls);
}

@mixin headingText($args: ()) {
    $defauls: (
        "weight": 600,
        "family": "primary",
        "color": $dark0,
        "size": $l,
    );

    @include text($args, $defauls);
}

@mixin largeText($args: ()) {
    $defauls: (
        "weight": 600,
        "family": "primary",
        "color": $dark0,
        "size": $xxl,
    );

    @include text($args, $defauls);
}

.bodyText {
    @include bodyText();
}

.paragraphText {
    @include paragraphText();
}

.metaText {
    @include metaText();
}

.subHeadingText {
    @include subHeadingText();
}

.headingText {
    @include headingText();
}

.largeText {
    @include largeText();
}
