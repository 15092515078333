@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.button {
    display: flex;
    justify-content: center;
    padding: 16px 20px;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 48px;
    box-shadow: 0 4px 8px rgba($secondary, 0.25);
    transition: .3s ease all;
}

.button-primary {
    background-color: $primary;
    box-shadow: 0 4px 8px rgba($primary, 0.25);
}

.button-secondary {
    background-color: $secondary;
    box-shadow: 0 4px 8px rgba($secondary, 0.25);
}

.button-faded {
    background-color: $light3;
    box-shadow: 0 4px 8px rgba($light3, 0.25);
}

.label {
    @include bodyText(("weight":600, "color": $dark0, "spacing": 2px));
    justify-content: center;
    align-items: center;
    transition: .3s ease all;
}

.label-faded {
    color: $dark1;
}

.label-primary {
    color: $dark0;
}

.button:hover {
    background-color: $dark0;
    box-shadow: 0 4px 8px rgba($dark0, 0.25);
}

.button:hover .label {
    color: $light0;
}