@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.select {
    flex: 0;
    width: 440px;
    max-width: 100%;
    margin: 10px 0;
    flex-direction: row;
    display: flex;
    position: relative;
}

.select :global .form-field  {
    width:100%;
    padding: 0 4px;
}

.skinSwatch {
    position: absolute;
    top: 100px;
    left: 40px;
    width: 40px;
    height: 40px;
    border-radius: 60px;
    // border: 2px solid white;
    z-index: 40;
}

.canvas {
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}
