@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.select {
    flex: 0;
    width: 480px;
    max-width: 100%;
    margin: 10px 0;
    flex-direction: row;
    display: flex;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    z-index: 5;
}

.select :global .form-field  {
    width:100%;
    padding: 0 4px;
}

.canvas {
    width: 100%;
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
}


.zoomIn {
    cursor: pointer;
    position: fixed;
    top: 48%;
    right: 10px;
    border-radius: 36px;
    width: 36px;
    height: 36px;
    background: $light0;
    color: $dark0;
    border: 2px solid $light4;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    z-index: 100;
    font-size: 2rem
}

.zoomOut {
    @extend .zoomIn;
    margin-top: 44px;
}

.zoomIn:hover, .zoomOut:hover {
    background: $light4;
    color: $dark0;
    border-color: $light0;
}