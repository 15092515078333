@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.select {
    flex: 0;
    width: 480px;
    max-width: 100%;
    margin: 10px 0;
    flex-direction: row;
    display: flex;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    z-index: 5;
}

.select :global .form-field  {
    width:100%;
    padding: 0 4px;
}

.canvas {
    width: 100%;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    display: flex;
}

.fields {
    position: absolute;
    right: 10px;
    top: 10px;
    bottom: 10px;
    background-color: $light2;
    width: 320px;
    border-radius: 7px;
    padding: 40px 20px 20px;
}