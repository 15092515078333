@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background-color: $light1;
}

.box {
    width: 100vh;
    height: 100vh;
    margin: 0 auto;
}